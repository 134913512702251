import React from 'react';
import { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PageHeader from '@common/PageHeader';
import IFrame from '@common/IFrame';
import Button, { IconButton } from '@common/Button';
import SmallProjects from './SmallProjects';
import JsProjects from './JsProjects';

import ProjectTemplate from './ProjectTemplate';
import { ProjectLinks, ProjectPreview, Tags } from './ProjectTemplate.style';
import Gridwrap from '@common/Gridwrap';

const ProjectsWrapper = styled.section`
  ${props => props.theme.spacing.sectionBottom};
`;
const Projects = () => {
  const [showAll, setShowAll] = useState(true);

  const handleShowAll = () => {
    setShowAll(false);
  };
  const projects = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fields: { posttype: { eq: "case-studies" } } }
          sort: { fields: fields___fileIndex, order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                excerpt
                iframe
                behance
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  return (
    <ProjectsWrapper>
      <PageHeader id="work">
        Work
        <p id="p">Check out some of my latest design case studies.</p>
      </PageHeader>
      <Gridwrap collapseHeight="1500px" showAll={!showAll}>
        {projects.allMarkdownRemark.edges.map(({ node }) => (
          <ProjectTemplate
            key={node.id}
            title={node.frontmatter.title}
            desc={node.frontmatter.excerpt}
            links={
              <ProjectLinks>
                <Button as={Link} to={node.fields.slug}>
                  Case Study
                </Button>
                {node.frontmatter.behance && (
                  <IconButton
                    label="Behance"
                    href={node.frontmatter.behance}
                    icon={['fab', 'behance']}
                  />
                )}
                {/* <Button target="__blank" as="a" href={node.frontmatter.demo}>
                Live Demo
              </Button> */}
                {/* <IconButton
                label="github"
                icon={['fab', 'github']}
                href={node.frontmatter.src}
              /> */}
              </ProjectLinks>
            }
            preview={
              <ProjectPreview>
                <IFrame
                  livedemo={!!node.frontmatter.iframe.match('codepen')}
                  src={node.frontmatter.iframe}
                />
                {/* <Tags>
                <FontAwesomeIcon icon={['fab', 'js']} />
                <FontAwesomeIcon icon={['fab', 'html5']} />
                <FontAwesomeIcon icon={['fab', 'css3']} />
              </Tags> */}
              </ProjectPreview>
            }
          />
        ))}

        {/* <SmallProjects /> */}
        {/* <JsProjects /> */}
        {showAll && (
          <Button onClick={handleShowAll} className="showall__button">
            Show all
          </Button>
        )}
      </Gridwrap>
    </ProjectsWrapper>
  );
};

export default Projects;
