import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, ValidationError } from '@formspree/react';
import Button from '@common/Button';
import PageHeader from '@common/PageHeader';

import {
  ContactWrapper,
  LeftContent,
  ContactBox,
  ContactForm,
} from './Contact.style';

import { LeftContent1, ContactBox1 } from './Thanks.style';

function Contact() {
  const [state, handleSubmit] = useForm('xgepddna');
  if (state.succeeded) {
    return (
      <ContactWrapper id="contact">
        <PageHeader>Get In Touch</PageHeader>
        <ContactBox1>
          <LeftContent1>
            <FontAwesomeIcon size={'5x'} icon="handshake" />
            <h3>Thank You</h3>
            <p>Your response has been submitted.</p>
          </LeftContent1>
        </ContactBox1>
      </ContactWrapper>
    );
  }
  return (
    <ContactWrapper id="contact">
      <PageHeader>Get In Touch</PageHeader>
      <ContactBox>
        <LeftContent>
          <FontAwesomeIcon size={'5x'} icon="envelope" />
          <h3>Let's Chat</h3>
          <p>Do You Have Any Queries?</p>
        </LeftContent>
        <ContactForm onSubmit={handleSubmit}>
          <label className="label__email">
            <span>Email</span>
            <input
              id="email"
              type="email"
              name="reply-to:"
              required
              placeholder="example@gmail.com"
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />
          </label>
          <label className="label__name">
            <span>Name</span>
            <input name="name:" type="text" required placeholder="Your Name" />
            <ValidationError prefix="Name" field="name" errors={state.errors} />
          </label>
          <label className="label__message">
            <span>Message</span>
            <textarea
              id="message"
              name="message:"
              required
              placeholder="Hey there!"
            />
            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
          </label>

          <Button type="submit" disabled={state.submitting}>
            <FontAwesomeIcon icon="paper-plane" /> Submit
          </Button>
        </ContactForm>
      </ContactBox>
    </ContactWrapper>
  );
}
export default Contact;
