import React from 'react';
import { useEffect } from 'react';
import Layout from '@components/Layout/Layout';
import SEO from '@components/seo';

import Home from '@components/Home/Home';
import About from '@components/About/About';
import Skills from '@components/Skills/Skills';
import Projects from '@components/Projects/Projects';
import JsProjects from '@src/components/Projects/JsProjects';
import SmallProjects from '@src/components/Projects/SmallProjects';
import Contact from '@components/Contact/Contact';
import 'typeface-nunito';
import Designs from '@src/components/Designs/Designs';
import { inject } from '@vercel/analytics';

const IndexPage = () => {
  useEffect(() => {
    inject();
  }, []);

  useEffect(() => {
    window.addEventListener(
      'contextmenu',
      function(e) {
        // do something here...
        e.preventDefault();
      },
      false
    );
  }, []);

  return (
    <Layout>
      <SEO title="Aman Seelay" />

      <Home />
      <About />
      <Skills />
      <Projects />
      <JsProjects />
      <SmallProjects />
      <Designs />
      <Contact />
    </Layout>
  );
};

export default IndexPage;
