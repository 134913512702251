import React from 'react';

import SkewBg from '@common/SkewBg';
import PageHeader from '@common/PageHeader';
import Flex from '@common/Flex';

import Quote from './Quote';
import Avatar from './Avatar';

import { AboutWrapper, AboutInfo } from './About.style';

const About = () => {
  return (
    <AboutWrapper id="about" style={{ marginBottom: 200 }}>
      <PageHeader>
        About Me
        <p id="p">Hey there 👋</p>
      </PageHeader>
      <SkewBg />
      <AboutInfo>
        <div>
          <Avatar src="SeelayProfile.jpg" />
        </div>
        <p>
          I'm a product designer based in Chandigarh, India with experience in
          delivering end-to-end UX/UI design for digital products. I'm
          passionate about improving the lives of others through design and
          constantly looking to learn new things every day.
          <br></br>
          <br></br>I love to create things by{' '}
          <a className="about__link" href="/#work">
            designing
          </a>{' '}
          ,{' '}
          <a className="about__link" href="/#projects">
            developing
          </a>{' '}
          or{' '}
          <a className="about__link" target="blank" href="//art.seelay.in">
            drawing
          </a>{' '}
          :)
        </p>
      </AboutInfo>

      <Flex justify="space-between" className="quotes__wrapper">
        <Quote>
          <h1>Creative Designer</h1>
        </Quote>
        <Quote>
          <h1>Frontend Developer</h1>
        </Quote>
        <Quote>
          <h1>Digital Artist</h1>
        </Quote>
      </Flex>
    </AboutWrapper>
  );
};

export default About;
