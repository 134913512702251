import styled from 'styled-components';

export const ContactBox1 = styled.div`
  width: calc(100% - 0px);
  position: relative;
  height: 400px;
  margin-bottom: 50px;
  margin-top: 150px;

  background: ${props => props.theme.gradient};

  padding: 20px;
  border-radius: 10px;

  @media ${props => props.theme.media.fablet} {
    width: 100%;
    padding: 10px;
    margin-top: 50px;
  }
  transition: 1s;
`;
export const LeftContent1 = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: inherit;
  text-align: center;

  &,
  p,
  h3 {
    color: #f8f8f8;
  }
  transition: 1s;
`;
