import React from 'react';
import { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import PageHeader from '@common/PageHeader';
import IFrame from '@common/IFrame';
import Button, { IconButton } from '@common/Button';

import { ProjectLinks, ProjectPreview } from './ProjectTemplate.style';
import Gridwrap from '@common/Gridwrap';
import ProjectTemplate from './ProjectTemplate';

const JsProjectsWrapper = styled.section`
  ${props => props.theme.spacing.sectionBottom};
`;

const JsProjects = () => {
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll(true);
  };
  const jsprojects = useStaticQuery(
    graphql`
      query {
        allJsprojectsJson {
          edges {
            node {
              description
              title
              id
              links {
                demo
                playstore
                iframe
              }
            }
          }
        }
      }
    `
  );

  return (
    <JsProjectsWrapper id="projects">
      <PageHeader>
        Personal Projects
        <p id="p">Made with ❤️ by yours truly.</p>
      </PageHeader>
      <Gridwrap collapseHeight="1500px" showAll={!showAll}>
        {jsprojects.allJsprojectsJson.edges.map(({ node }) => (
          <ProjectTemplate
            key={node.id}
            title={node.title}
            desc={node.description}
            links={
              <ProjectLinks>
                <Button target="__blank" as="a" href={node.links.demo + ''}>
                  Live Demo
                </Button>
                {node.links.playstore && (
                  <IconButton
                    label="Available on Google Play Store"
                    href={node.links.playstore}
                    icon={['fab', 'google-play']}
                  />
                )}
              </ProjectLinks>
            }
            preview={
              <ProjectPreview>
                <IFrame livedemo src={node.links.iframe} />
              </ProjectPreview>
            }
          />
        ))}
        {showAll && (
          <Button onClick={handleShowAll} className="showall__button">
            Show all
          </Button>
        )}
      </Gridwrap>
    </JsProjectsWrapper>
  );
};

export default JsProjects;
